import React from 'react';
import PlugIcon from '@assets/svgs/plug-icon.svg';
import styles from './404.module.scss';

export default function Custom404() {
  return (
    <div className={styles.wrapErrorContent}>
      <div className={styles.wrapError}>
        <PlugIcon />
        <div className={styles.wrapErrorText}>
          <div className={`h5 ${styles.errorText}`}>Σφάλμα</div>
          <div className={`h1 ${styles.fofText}`}>404</div>
          <div className={`h4 ${styles.notFoundText}`}>Η σελίδα δεν βρέθηκε</div>
        </div>
      </div>
    </div>
  );
}
